import React, { useState, Fragment } from 'react';
import { Table, Button } from 'react-bootstrap';
import {
  blockEmployee,
  accessEmployee,
  deleteEmployee,
} from '../functions/employees';
import UpdateEmployeePasswordModal from './UpdateEmployeePasswordModal';

const BootstrapTable = ({ employees, loadEmployees }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({});

  const closeModal = () => setModalOpen(false);

  const handleBlock = async (id, username) => {
    await blockEmployee(id, username, loadEmployees);
  };

  const handleAccess = async (id, username) => {
    await accessEmployee(id, username, loadEmployees);
  };

  const handleDelete = async (id, username) => {
    await deleteEmployee(id, username, loadEmployees);
  };

  return (
    <Fragment>
      <Table responsive striped bordered hover>
        <caption>List of employees</caption>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Username</th>
            <th scope='col'>Status</th>
            <th scope='col'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={index}>
              <th>{employee.id}</th>
              <td>{employee.username}</td>
              <td>{employee.status === 2 ? 'Access granted' : 'Blocked'}</td>
              <td>
                <div className='d-flex flex-lg-row flex-sm-column flex-column'>
                  {employee.status === 2 ? (
                    <Button
                      variant='danger'
                      className='mb-lg-0 mr-lg-2 mb-1'
                      size='sm'
                      onClick={async () => {
                        await handleBlock(employee.id, employee.username);
                      }}
                    >
                      Block
                    </Button>
                  ) : (
                    <Button
                      variant='success'
                      className='mb-lg-0 mr-lg-2 mb-1'
                      size='sm'
                      onClick={() =>
                        handleAccess(employee.id, employee.username)
                      }
                    >
                      Access
                    </Button>
                  )}
                  <Button
                    onClick={() => handleDelete(employee.id, employee.username)}
                    className='mb-lg-0 mr-lg-2 mb-1'
                    variant='danger'
                    size='sm'
                  >
                    Delete
                  </Button>
                  <Button
                    variant='info'
                    className='mb-lg-0 mr-lg-2 mb-1'
                    size='sm'
                    onClick={() => {
                      setModalOpen(true);
                      setCurrentEmployee(employee);
                    }}
                  >
                    Update Password
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UpdateEmployeePasswordModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        currentEmployee={currentEmployee}
      />
    </Fragment>
  );
};

export default BootstrapTable;
