import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import heroImage from '../assets/images/undraw_factory_dy0a.svg';

const Home = () => {
  useEffect(() => {
    document.title = 'Ambica Roadlines - Home';
  }, []);

  return (
    <Container>
      <section className='my-5 py-5 text-xl-left text-lg-left text-md-center text-sm-center text-center'>
        <Row className='align-items-center'>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <h1 className='title font-weight-bolder'>
              Welcome to <span className='text-primary'>Ambica</span>
              Roadlines.
            </h1>
            <p className='subtitle text-muted'>
              We deliver your cargo, whenever you need it and wherever you need
              it.
            </p>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <img
              src={heroImage}
              className='img-fluid mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5'
              alt='Ambica roadlines hero images'
            />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default Home;
