import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const SkeletonTableLoading = () => {
  return (
    <Fragment>
      <Row>
        <Col xl={{ span: 6, offset: 6 }}>
          <Row>
            <Col xl={8} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 12 }}>
              <Skeleton height={38} />
            </Col>
            <Col xl={4} md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 12 }}>
              <Skeleton height={38} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Skeleton count={6} height={50} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SkeletonTableLoading;
