import React from 'react';

const ReactGoogleMap = () => {
  return (
    <div className='embed-responsive embed-responsive-16by9'>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.52373598028748!2d69.01194218231555!3d22.414727357997368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbd9b044406b9ca2e!2sAmbica%20Roadlines!5e0!3m2!1sen!2sin!4v1606034659912!5m2!1sen!2sin'
        className='embed-responsive-item'
        frameBorder='0'
        style={{ border: 0 }}
        allowFullScreen
        aria-hidden='false'
        title='Google Map'
      ></iframe>
    </div>
  );
};

export default ReactGoogleMap;
