import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form as BootstrapForm,
  Spinner,
} from 'react-bootstrap';
import { RiRegisteredLine } from 'react-icons/ri';

import FormControl from '../components/FormControl';
import { getCurrentUser } from '../helpers/auth';
import { employeepasswordChange } from '../functions/employees';

const Profile = () => {
  useEffect(() => {
    document.title = 'Ambica Roadlines - Profile';
  }, []);

  const user = getCurrentUser();

  const initialValues = {
    currentpassword: '',
    newpassword: '',
    retypenewpassword: '',
  };

  const validationSchema = Yup.object({
    currentpassword: Yup.string()
      .required()
      .min(3)
      .max(20)
      .label('Current Password'),
    newpassword: Yup.string().required().min(3).max(20).label('New Password'),
    retypenewpassword: Yup.string()
      .required()
      .min(3)
      .max(20)
      .oneOf(
        [Yup.ref('newpassword'), null],
        'New Password & Retype New Password need to be the same'
      )
      .label('Retype New Password'),
  });

  const onSubmit = async (values, helpers) => {
    await employeepasswordChange(values, helpers);
  };

  return (
    <Container>
      <div className='mt-4'>
        <Row>
          <Col xl={4}>
            <Card className='text-center mb-3'>
              <Card.Body>
                <img
                  src={`https://www.gravatar.com/avatar/${user.id}?s=100&d=identicon&r=PG`}
                  alt={`${user.username}'s Profile`}
                  className='img-fluid img-thumbnail mb-4 mx-auto d-block rounded-circle'
                />
                <Card.Title
                  as='h3'
                  className='text-capitalize font-weight-bold'
                >
                  {user.username}
                </Card.Title>
                <Card.Subtitle className='text-muted'>
                  {user.role === '2' ? 'Employee' : 'Consignor'}
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer className='text-muted d-flex justify-content-center align-items-center'>
                <RiRegisteredLine />
                &nbsp;Ambica Roadlines
              </Card.Footer>
            </Card>
          </Col>
          <Col xl={8}>
            <Card>
              <Card.Body>
                <Card.Title>Change password</Card.Title>
                <Card.Subtitle className='text-muted'>
                  It's a good idea to use a strong password that you don't use
                  elsewhere
                </Card.Subtitle>
                <hr />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <BootstrapForm.Group controlId='currentpassword'>
                        <FormControl
                          type='password'
                          label='Current Password'
                          name='currentpassword'
                          tabIndex={1}
                          autoComplete='false'
                        />
                      </BootstrapForm.Group>
                      <BootstrapForm.Group controlId='newpassword'>
                        <FormControl
                          type='password'
                          label='New Password'
                          name='newpassword'
                          tabIndex={2}
                          autoComplete='false'
                        />
                      </BootstrapForm.Group>
                      <BootstrapForm.Group controlId='retypenewpassword'>
                        <FormControl
                          type='password'
                          label='Retype New Password'
                          name='retypenewpassword'
                          tabIndex={3}
                          autoComplete='false'
                        />
                      </BootstrapForm.Group>
                      <Button
                        type='submit'
                        tabIndex={4}
                        block
                        disabled={!(dirty && isValid) || isSubmitting}
                      >
                        {isSubmitting ? (
                          <Spinner animation='border' size='sm' />
                        ) : (
                          'Change Password'
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Profile;
