import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import BootstrapAlert from '../components/BootstrapAlert';
import EmployeesTable from '../components/EmployeesTable';
import AddEmployeeModal from '../components/AddEmployeeModal';
import SkeletonTableLoading from '../components/loading/SkeletonTableLoading';
import NoRecord from '../components/NoRecord';

import { getEmployeesList } from '../functions/employees';
import { searchData } from '../functions/searchFromTable';

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shoeAlert, setShoeAlert] = useState(false);
  const [error, setError] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');

  const closeModal = () => setModalOpen(false);

  const loadEmployees = async () => {
    await getEmployeesList(setEmployees, setShoeAlert, setError, setLoading);
  };

  useEffect(() => {
    document.title = 'Ambica Roadlines - Employees';
    setLoading(true);
    loadEmployees();
  }, []);

  return (
    <Container>
      <div className='mt-3'>
        {loading ? (
          <SkeletonTableLoading />
        ) : shoeAlert ? (
          <BootstrapAlert
            message={error}
            variant='danger'
            setShoeAlert={setShoeAlert}
            setError={setError}
          />
        ) : (
          <Fragment>
            <Row>
              <Col xl={{ span: 6, offset: 6 }}>
                <Row>
                  <Col
                    xl={8}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 12 }}
                  >
                    {employees.length > 0 && (
                      <Form.Control
                        type='search'
                        placeholder='Search...'
                        className='mt-xs-1'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    )}
                  </Col>
                  <Col
                    xl={4}
                    md={{ span: 4 }}
                    sm={{ span: 4 }}
                    xs={{ span: 12 }}
                  >
                    <Button onClick={() => setModalOpen(true)} block>
                      Create Employee
                    </Button>
                  </Col>
                  <AddEmployeeModal
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    loadEmployees={loadEmployees}
                  />
                </Row>
              </Col>
            </Row>
            <hr />
            {employees.length <= 0 ? (
              <NoRecord />
            ) : (
              <Row>
                <Col>
                  <EmployeesTable
                    employees={searchData(employees, search)}
                    loadEmployees={loadEmployees}
                  />
                </Col>
              </Row>
            )}
          </Fragment>
        )}
      </div>
    </Container>
  );
};

export default Employees;
