import {
  createEmployee,
  getEmployees,
  employeeBlock,
  employeeAccess,
  employeeDelete,
  employeeUpdatePassword,
  employeeSelfPasswordChange,
} from '../helpers/employees';

import { confirm_dialog, toast_notification } from './notification';

export async function addEmployee(
  username,
  password,
  closeModal,
  loadEmployees,
  helpers
) {
  await createEmployee({ username, password })
    .then((response) => {
      if (response && response.status === 200) {
        helpers.resetForm();
        closeModal();
        toast_notification(
          'success',
          `${username} now employee at Ambica Roadlines 🎊`
        );
        loadEmployees();
      }
    })
    .catch((error) => {
      if (error && error.response.status !== 200) {
        helpers.setFieldError('username', error.response.data.message);
        helpers.setFieldValue('password', '', false);
        helpers.setFieldTouched('password', false, false);
      }
      if (error && error.response.status !== 200) {
        helpers.setFieldError('username', error.response.data.message);
        helpers.setFieldValue('username', '', false);
        helpers.setFieldValue('password', '', false);
        helpers.setFieldTouched('password', false, false);
      }
    });
}

export function getEmployeesList(
  setEmployees,
  setShoeAlert,
  setError,
  setLoading
) {
  return getEmployees()
    .then((response) => {
      if (response.status === 200) {
        setEmployees(response.data);
        setShoeAlert(false);
        setError({});
        setLoading(false);
      }
    })
    .catch((error) => {
      if (error && error.response.status === 401) {
        setError(error.response.data.message);
        setShoeAlert(true);
      }
      setLoading(false);
    });
}

export function blockEmployee(id, username, loadEmployees) {
  return confirm_dialog(
    'Are you sure?',
    `You want to block <b>${username}</b> !`,
    'warning',
    'Yes, block it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await employeeBlock(id)
          .then((response) => {
            if (response.status === 200) {
              toast_notification(
                'success',
                `${username} has been blocked now!`
              );
              loadEmployees();
            } else {
            }
          })
          .catch((error) => {
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
}

export function accessEmployee(id, username, loadEmployees) {
  return confirm_dialog(
    'Are you sure?',
    `You want to give access to <b>${username}</b> !`,
    'warning',
    'Yes, Give Access!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await employeeAccess(id)
          .then((response) => {
            if (response.status === 200) {
              toast_notification('success', `${username} has access now!`);
              loadEmployees();
            } else {
            }
          })
          .catch((error) => {
            // console.log(error.response);
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
}

export async function deleteEmployee(id, username, loadEmployees) {
  return confirm_dialog(
    'Are you sure?',
    `You want to delete <b>${username}</b> !`,
    'warning',
    'Yes, delete it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await employeeDelete(id)
          .then((response) => {
            if (response.status === 200) {
              toast_notification('success', `${username} delete successfully!`);
              loadEmployees();
            } else {
              toast_notification('error', `Something goes wrong! 👎🏻`);
            }
          })
          .catch((error) => {
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
}

export async function updateEmployeePassword(
  id,
  password,
  username,
  closeModal,
  helpers
) {
  return confirm_dialog(
    'Are you sure?',
    `You want to update password of <b>${username}</b>`,
    'warning',
    'Yes, Update!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await employeeUpdatePassword(id, password)
          .then((response) => {
            if (response.status === 200) {
              helpers.resetForm();
              closeModal();
              toast_notification(
                'success',
                `${username}'s password has been changed!`
              );
            } else {
              toast_notification('error', `Something goes wrong! 👎🏻`);
            }
          })
          .catch((error) => {
            console.log(error.response);
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
}

export const employeepasswordChange = async (values, helpers) => {
  await employeeSelfPasswordChange(values)
    .then((response) => {
      if (response && response.status === 200) {
        helpers.resetForm();
        toast_notification(
          'success',
          `Your password has been changed successfully!`
        );
      }
    })
    .catch((error) => {
      toast_notification('error', `${error.response.data.message}!`);
    });
};
