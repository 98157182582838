import axios from 'axios';
import JwtDecode from 'jwt-decode';

const BASE_URL = process.env.REACT_APP_API;

export const isAuthenticated = async () => {
  await axios
    .get(`${BASE_URL}/users/isauthenticated`, {
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json',
      },
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status !== 200) {
        localStorage.clear();
        window.location = '/login';
      }
    });
};

export const login = async ({ username, password }) => {
  const result = await axios.post(
    `${BASE_URL}/users/authenticate`,
    JSON.stringify({ username, password }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result;
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const getJwt = () => {
  return localStorage.getItem('token');
};

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem('token');
    return JwtDecode(jwt);
  } catch (error) {
    return null;
  }
};

export const logout = () => {
  if (getJwt()) {
    localStorage.clear();
    window.location = '/login';
  }
};
