import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm, Spinner } from 'react-bootstrap';

import BootstrapModal from './BootstrapModal';
import FormControl from './FormControl';
import { updateEmployeePassword } from '../functions/employees';

const UpdateEmployeePasswordModal = ({
  modalOpen,
  closeModal,
  currentEmployee,
}) => {
  const initialValues = {
    username: currentEmployee.username,
    password: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string().required().min(3).max(20).label('Password'),
  });

  const onSubmit = async ({ username, password }, helpers) => {
    await updateEmployeePassword(
      currentEmployee.id,
      password,
      username,
      closeModal,
      helpers
    );
  };

  return (
    <BootstrapModal
      show={modalOpen}
      onHide={closeModal}
      title='Update Password'
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <BootstrapForm.Group controlId='username'>
              <FormControl
                type='text'
                label='Username'
                name='username'
                tabIndex={1}
                readOnly
              />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='password'>
              <FormControl
                type='password'
                label='Password'
                name='password'
                tabIndex={2}
                autoComplete='false'
              />
            </BootstrapForm.Group>
            <Button
              type='submit'
              tabIndex={3}
              block
              disabled={!(dirty && isValid) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation='border' size='sm' />
              ) : (
                'Update Employee Password'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </BootstrapModal>
  );
};

export default UpdateEmployeePasswordModal;
