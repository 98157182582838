import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

// <- Components ->
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

// <- Pages ->
import Home from '../pages/Home';
import NotFound from '../pages/404/NotFound';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Employees from '../pages/Employees';
import Consignors from '../pages/Consignors';
import Consignees from '../pages/Consignees';
import Profile from '../pages/Profile';
import CreateBilty from '../pages/Bills/CreateBilty';
import Bilty from '../pages/Bills/Bilty';

// <- Functions ->
import { isAuthenticated, getCurrentUser } from '../helpers/auth';

// <- Routes ->
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import GetBill from '../pages/Bills/GetBill';

const Routes = () => {
  const user = getCurrentUser();

  return (
    <React.Fragment>
      <Router>
        <Navigation />
        <Switch>
          <Route exact={true} path='/login' component={Login} />
          <ProtectedRoute
            exact={true}
            path='/dashboard'
            component={Dashboard}
          />
          <ProtectedRoute
            exact={true}
            path='/consignors'
            component={Consignors}
          />

          <Route
            exact={true}
            path='/consignee/:id'
            render={(routerProps) => {
              return isAuthenticated() && user ? (
                <Consignees {...routerProps} />
              ) : (
                <Redirect to='/login' />
              );
            }}
          />

          <ProtectedRoute exact={true} path='/profile' component={Profile} />
          <ProtectedRoute exact={true} path='/bills/bilty' component={Bilty} />
          <ProtectedRoute
            exact={true}
            path='/bills/bilty/create'
            component={CreateBilty}
          />
          <Route
            exact={true}
            path='/bills/bilty/:billId'
            render={(routerProps) => {
              return isAuthenticated() && user ? (
                <GetBill {...routerProps} />
              ) : (
                <Redirect to='/login' />
              );
            }}
          />
          <AdminRoute exact={true} path='/employees' component={Employees} />
          <Route
            exact={true}
            path='/'
            render={() => (user ? <Redirect to='/dashboard' /> : <Home />)}
          />
          <Route path='/404' component={NotFound} />
          <Redirect to='/404' />
        </Switch>

        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default Routes;
