import React from 'react';
import { Alert } from 'react-bootstrap';

const BootstrapAlert = ({
  dismissible,
  message,
  variant,
  setShoeAlert,
  setError,
}) => {
  return (
    <Alert
      variant={variant}
      onClose={() => {
        setShoeAlert(false);
        setError({});
      }}
      dismissible={dismissible}
    >
      {message}
    </Alert>
  );
};

export default BootstrapAlert;
