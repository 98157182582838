import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm, Spinner } from 'react-bootstrap';

import BootstrapModal from './BootstrapModal';
import FormControl from './FormControl';
import { addConsignor } from '../functions/consignors';

const AddConsignorsModal = ({ modalOpen, closeModal, loadConsignors }) => {
  const initialValues = {
    username: '',
    name: '',
    number: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required()
      .min(5)
      .max(30)
      .matches(
        /^[a-zA-Z0-9]*$/,
        'Username should contain only alphanumeric value.'
      )
      .label('Username'),
    name: Yup.string().required().min(5).max(50).label('Name'),
    number: Yup.string()
      .matches(/^[0-9]*$/, 'Phone Number should contain only numerical value.')
      .required()
      .min(10)
      .max(10)
      .label('Phone Number'),
    password: Yup.string().required().min(3).max(20).label('Password'),
  });

  const onSubmit = async (values, helpers) => {
    console.log(values);
    await addConsignor(values, closeModal, loadConsignors, helpers);
  };

  return (
    <BootstrapModal
      show={modalOpen}
      onHide={closeModal}
      title='Create Consignor'
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <BootstrapForm.Group controlId='username'>
              <FormControl
                type='text'
                label='Username'
                name='username'
                tabIndex={1}
              />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='name'>
              <FormControl type='text' label='Name' name='name' tabIndex={2} />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='number'>
              <FormControl
                type='tel'
                label='Phone number'
                name='number'
                tabIndex={3}
              />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='password'>
              <FormControl
                type='password'
                label='Password'
                name='password'
                tabIndex={4}
                autoComplete='false'
              />
            </BootstrapForm.Group>
            <Button
              type='submit'
              tabIndex={5}
              block
              disabled={!(dirty && isValid) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation='border' size='sm' />
              ) : (
                'Create Consignor'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </BootstrapModal>
  );
};

export default AddConsignorsModal;
