import axios from 'axios';
import { getJwt } from '../auth';

const BASE_URL = process.env.REACT_APP_API;

export const getInitialBilling = async () => {
  return await axios({
    method: 'GET',
    url: `${BASE_URL}/bill/initialbilling`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
};

export const createBilty = async (values) => {
  return await axios({
    method: 'POST',
    url: `${BASE_URL}/bill/createbill`,
    headers: {
      authorization: `Bearer ${getJwt()}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(values),
  });
};

export const getBillty = async (billId) => {
  return await axios({
    method: 'GET',
    url: `${BASE_URL}/bill/${billId}`,
    headers: {
      authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const listOfBilty = async (page) => {
  return await axios({
    method: 'GET',
    url: `${BASE_URL}/bill/list/${page}`,
    headers: {
      authorization: `Bearer ${getJwt()}`,
    },
  });
};
