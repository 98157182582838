import {
  createBilty,
  getInitialBilling,
  getBillty,
  listOfBilty,
} from '../../helpers/bills';

export const initialBilling = async (
  setConsignors,
  setLoading,
  setFinancialYear
) => {
  return await getInitialBilling()
    .then((response) => {
      if (response && response.status === 200) {
        setConsignors(response.data.consignors);
        setFinancialYear(response.data.financialyear);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.log(error.response);
      setLoading(false);
      setConsignors([]);
      setFinancialYear('');
    });
};

export const generateBitly = async (values) => {
  return await createBilty(values);
};

export const GetBilty = async (
  billId,
  setData,
  setDataItems,
  setError,
  setLoading
) => {
  return await getBillty(billId)
    .then((response) => {
      setData(response.data);
      setDataItems(response.data.items);
      setLoading(false);
      setError('');
    })
    .catch((error) => {
      if (error && error.response !== 200) {
        setError(error.response.data.message);
        setLoading(false);
      }
      setLoading(false);
      console.log(error.response);
    });
};

export const displayListOfBilty = async (
  page,
  setItems,
  setErrors,
  setTotalResults,
  setLoading
) => {
  return await listOfBilty(page)
    .then((response) => {
      console.log(response.data);
      setItems(response.data.items);
      setTotalResults(response.data.total_Results);
      setLoading(false);
    })
    .catch((error) => {
      setItems([]);
      setTotalResults(null);
      setErrors(error.response.data.message);
      setLoading(false);
    });
};
