import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BiltyListTable = ({ items }) => {
  return (
    <Table responsive striped bordered hover>
      <caption>List of Bilty</caption>
      <thead className='thead-dark'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>G.C Number</th>
          <th scope='col'>Truck Number</th>
          <th scope='col'>Date</th>
          <th scope='col'>Consignor Name</th>
          <th scope='col'>Consignee Name</th>
          <th scope='col'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <th>{item.id}</th>
            <td>{item.gcNumber}</td>
            <td>{item.truckNumber}</td>
            <td>{item.date}</td>
            <td>{item.consignorName}</td>
            <td>{item.consigneeName}</td>
            <td>
              <Button
                className='mb-lg-0 mr-lg-2 mb-1'
                as={Link}
                to={`/bills/bilty/${item.id}`}
                size='sm'
                variant='info'
              >
                View
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BiltyListTable;
