import axios from 'axios';
import { getJwt } from './auth';

const BASE_URL = process.env.REACT_APP_API;

export const getEmployees = async () => {
  const result = await axios.get(`${BASE_URL}/users/GetEmployees`, {
    headers: {
      authorization: `Bearer ${getJwt()}`,
    },
  });
  return result;
};

export const createEmployee = async ({ username, password }) => {
  const result = await axios.post(
    `${BASE_URL}/users/addemployee`,
    JSON.stringify({ username, password }),
    {
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return result;
};

export const employeeBlock = async (id) => {
  const result = await axios({
    method: 'PUT',
    url: `${BASE_URL}/users/employeeblock/${id}`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
  return result;
};

export const employeeAccess = async (id) => {
  const result = await axios({
    method: 'PUT',
    url: `${BASE_URL}/users/employeeallow/${id}`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
  return result;
};

export const employeeDelete = async (id) => {
  const result = await axios({
    method: 'DELETE',
    url: `${BASE_URL}/users/employeedelete/${id}`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
  return result;
};

export const employeeUpdatePassword = async (id, password) => {
  const result = await axios({
    method: 'PUT',
    url: `${BASE_URL}/users/passupadmin`,
    data: JSON.stringify({ id, password }),
    headers: {
      authorization: `Bearer ${getJwt()}`,
      'Content-Type': 'application/json',
    },
  });
  return result;
};

export const employeeSelfPasswordChange = async ({
  currentpassword,
  newpassword,
}) => {
  return await axios({
    method: 'PUT',
    url: `${BASE_URL}/users/password`,
    data: {
      oldpass: currentpassword,
      newpass: newpassword,
    },
    headers: {
      authorization: `Bearer ${getJwt()}`,
      'Content-Type': 'application/json',
    },
  });
};
