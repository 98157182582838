import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import image_404 from '../../assets/images/404.svg';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Ambica Roadlines - 404';
  }, []);

  return (
    <Container>
      <div className='my-5'>
        <img
          src={image_404}
          className='img-fluid mx-auto d-block'
          alt='404 page not found'
          width='350'
        />
        <div className='mt-5 text-center'>
          <h3 className='font-weight-bolder'>Look like you're lost</h3>
          <p className='text-muted'>
            the page you are looking for not available!
          </p>
          <Link className='btn btn-outline-dark' to='/'>
            Home
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
