import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { Form as BootstrapForm } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { MdDelete } from 'react-icons/md';

import {
  addConsignee,
  allConsignees,
  deleteConsignee,
} from '../functions/consignees';
import FormControl from '../components/FormControl';
import NoRecord from '../components/NoRecord';

const Consignees = () => {
  const { id } = useParams();

  const [consignees, setConsignees] = useState([]);
  const [consignorsName, setConsignorsName] = useState('');
  const [consignorsUsername, setConsignorsUsername] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const getConsignees = async (id) => {
    setLoading(true);
    setError('');
    await allConsignees(
      id,
      setConsignees,
      setConsignorsName,
      setConsignorsUsername,
      setError
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = 'Ambica Roadlines - Consignee';
    getConsignees(id);
  }, [id]);

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required()
      .label('Name')
      .matches(/^[A-Za-z\s]*$/, 'Name should contain only alphabet value.'),
  });

  const onSubmit = async ({ name }, helpers) => {
    await addConsignee(name, id, consignorsUsername, helpers).then(() => {
      getConsignees(id);
    });
  };

  const handleDelete = async (did, name) => {
    await deleteConsignee(did, name).then(() => {
      getConsignees(id);
    });
  };

  return (
    <Container>
      <div className='mt-3'>
        <Row>
          {loading && (
            <Fragment>
              <Col xl={8} lg={6}>
                <Skeleton count={5} height={50} />
              </Col>
              <Col xl={4} lg={6}>
                <Skeleton height={215} />
              </Col>
            </Fragment>
          )}

          {!loading && (
            <Col xl={8} lg={6}>
              {error && <Alert variant='danger'>{error}</Alert>}
              {!error && (
                <BootstrapForm.Group>
                  <Card>
                    <Card.Header>
                      {consignorsName} ({consignorsUsername})'s Consignee's
                    </Card.Header>
                    <ListGroup variant='flush'>
                      {consignees.length <= 0 && <NoRecord />}
                      {consignees.map((consignee, index) => (
                        <ListGroup.Item
                          key={index}
                          className='d-flex justify-content-between align-items-center'
                        >
                          {consignee.name}
                          <OverlayTrigger
                            key={index}
                            placement='left'
                            overlay={
                              <Popover>
                                <Popover.Content id={`tooltip-${index}`}>
                                  Delete <strong>{consignee.name}</strong>
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <Button
                              size='sm'
                              onClick={() =>
                                handleDelete(consignee.id, consignee.name)
                              }
                              variant='secondary'
                            >
                              <MdDelete size={20} />
                            </Button>
                          </OverlayTrigger>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card>
                </BootstrapForm.Group>
              )}
            </Col>
          )}
          {!loading && (
            <Col xl={4} lg={6}>
              {!error && (
                <BootstrapForm.Group>
                  <Card>
                    <Card.Header>Add consignee</Card.Header>
                    <Card.Body>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ isSubmitting, isValid, dirty }) => (
                          <Form>
                            <BootstrapForm.Group controlId='name'>
                              <FormControl
                                type='text'
                                label='Consignee Name'
                                name='name'
                                tabIndex={1}
                              />
                            </BootstrapForm.Group>
                            <Button
                              type='submit'
                              tabIndex={3}
                              block
                              disabled={!(dirty && isValid) || isSubmitting}
                            >
                              {isSubmitting ? (
                                <Spinner animation='border' size='sm' />
                              ) : (
                                'Add'
                              )}
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </BootstrapForm.Group>
              )}
            </Col>
          )}
        </Row>
      </div>
    </Container>
  );
};

export default Consignees;
