import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import * as Yup from 'yup';

import FormControl from '../components/FormControl';
import BootstrapAlert from '../components/BootstrapAlert';

import { login, setToken } from '../helpers/auth';

const Login = () => {
  useEffect(() => {
    document.title = 'Ambica Roadlines - Login';
  }, []);

  const [shoeAlert, setShoeAlert] = useState(false);
  const [error, setError] = useState({});

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required().label('Username'),
    password: Yup.string().required().min(3).max(20).label('Password'),
  });

  const onSubmit = async ({ username, password }, helpers) => {
    // console.log(helpers);
    await login({ username, password })
      .then((response) => {
        // console.log(response);
        if (response && response.status === 200) {
          setToken(response.data.token);
          helpers.resetForm();
          helpers.setTouched({ username, password }, false);
          setShoeAlert(false);
          setError({});
          window.location = '/dashboard';
        }
      })
      .catch((error) => {
        if (error && error.response.status !== 200) {
          setError(error.response.data.message);
          setShoeAlert(true);
        }
        helpers.resetForm();
        helpers.setTouched({ username, password }, false);
      });
  };

  return (
    <Container>
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 6, offset: 3 }}
          md={{ span: 8, offset: 2 }}
          sm={{ span: 10, offset: 1 }}
          xs={12}
        >
          <Card className='my-5'>
            <Card.Header className='text-center'>
              <Card.Title>
                <strong>Welcome back!</strong>
              </Card.Title>
              <Card.Subtitle className='text-muted small'>
                We're so excited to see you again!
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              {shoeAlert && (
                <BootstrapAlert
                  message={error}
                  variant='danger'
                  setShoeAlert={setShoeAlert}
                  setError={setError}
                  dismissible='true'
                />
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <BootstrapForm.Group controlId='username'>
                      <FormControl
                        type='text'
                        label='Username'
                        name='username'
                        tabIndex={1}
                      />
                    </BootstrapForm.Group>
                    <BootstrapForm.Group controlId='password'>
                      <FormControl
                        type='password'
                        label='Password'
                        name='password'
                        tabIndex={2}
                        autoComplete='false'
                      />
                    </BootstrapForm.Group>
                    <Button
                      type='submit'
                      tabIndex={3}
                      block
                      disabled={!(dirty && isValid) || isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner animation='border' size='sm' />
                      ) : (
                        'Login'
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
