import React, { Fragment } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BiTimeFive } from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { Col, Container, Row } from 'react-bootstrap';
import { getCurrentUser } from '../helpers/auth';
import { Link } from 'react-router-dom';
import ReactGoogleMap from './ReactGoogleMap';

const Footer = () => {
  const user = getCurrentUser();

  return (
    <Fragment>
      {!user && (
        <div className='mt-5'>
          <Fragment>
            <hr className='mb-0' />
            <div className='py-5 bg-light'>
              <Container>
                <Row>
                  <Col xl={4}>
                    <h4 className='text-dark font-weight-bold'>Contact</h4>
                    <ul className='list-unstyled text-secondary'>
                      <li>
                        <div className='d-flex align-items-center'>
                          <FiPhone />
                          &nbsp;Phone&nbsp;:&nbsp;
                          <a href='tel:+919998818312'>+91 99988 18312</a>
                        </div>
                      </li>
                      <li>
                        <div className='d-flex align-items-center'>
                          <FaWhatsapp />
                          &nbsp;WhatsApp&nbsp;:&nbsp;
                          <a href='https://wa.me/919998818312'>
                            +91 99988 18312
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className='d-flex align-items-center'>
                          <HiOutlineMail />
                          &nbsp;Mail&nbsp;:&nbsp;
                          <a href='mailto:info@ambicaroadlines.me'>
                            info@ambicaroadlines.me
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className='d-flex align-items-center'>
                          <BiTimeFive />
                          &nbsp;Office Time&nbsp;:&nbsp;09:00 AM to 07:00 PM
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col xl={8}>
                    <ReactGoogleMap />
                  </Col>
                </Row>
              </Container>
            </div>
          </Fragment>

          <div className={`bg-dark text-secondary py-3`}>
            <Container>
              <Row>
                <Col xl={6} lg={6} md={6}>
                  <div>Copyright 2020&nbsp;&#169;&nbsp;AmbicaRoadlines</div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className='text-lg-right'>
                    <Link className='text-decoration-none' to='/'>
                      Terms and conditions
                    </Link>
                    <span className='mx-2'>•</span>
                    <Link className='text-decoration-none' to='/'>
                      Privacy policy
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Footer;
