import axios from 'axios';
import { getJwt } from './auth';

const BASE_URL = process.env.REACT_APP_API;

export const fetchConsignees = async (id) => {
  return await axios({
    method: 'GET',
    url: `${BASE_URL}/users/getconsignees/${id}`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
};

export const createConsignee = async (name, cid) => {
  const result = axios.post(
    `${BASE_URL}/users/AddConsignee`,
    JSON.stringify({ name, cid }),
    {
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return result;
};

export const consigneeDelete = async (id) => {
  const result = await axios({
    method: 'DELETE',
    url: `${BASE_URL}/users/consignee/${id}`,
    headers: { authorization: `Bearer ${getJwt()}` },
  });
  return result;
};
