import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { logout, getCurrentUser } from '../helpers/auth';

const Navigation = () => {
  const user = getCurrentUser();

  return (
    <Navbar bg='dark' variant='dark' expand='lg'>
      <Container>
        <Navbar.Brand as={Link} to={user ? '/dashboard' : '/'}>
          Ambica<span>Roadlines</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            {!user && (
              <Fragment>
                <NavLink className='nav-link' exact to='/'>
                  Home
                </NavLink>
                <NavLink className='nav-link' exact to='/login'>
                  Login
                </NavLink>
              </Fragment>
            )}
            {user && (
              <Fragment>
                <NavLink className='nav-link' to='/dashboard'>
                  Dashboard
                </NavLink>
                {user.role === '1' && (
                  <NavLink className='nav-link' to='/employees'>
                    Employees
                  </NavLink>
                )}
                <NavLink className='nav-link' to='/consignors'>
                  Consignors
                </NavLink>
                <NavDropdown title='Bills' id='bills-dropdown'>
                  <NavDropdown.Item as={NavLink} exact to='/bills/bilty'>
                    Bilty
                  </NavDropdown.Item>
                </NavDropdown>
                {user.role !== '1' && (
                  <NavLink className='nav-link' to='/profile'>
                    Profile
                  </NavLink>
                )}
                <NavLink
                  className='nav-link'
                  exact
                  to='/login'
                  onClick={() => logout()}
                >
                  Logout
                </NavLink>
              </Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
