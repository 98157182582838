import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser, isAuthenticated } from '../helpers/auth';

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated() ? (
          getCurrentUser().role === '1' ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/404',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AdminRoute;
