import {
  getConsignors,
  createConsignor,
  updateConsignor,
  deleteConsignor,
  consignorPasswordUpdate,
} from '../helpers/consignors';
import { toast_notification, confirm_dialog } from './notification';

export function getConsignorsList(setConsignors, setLoading) {
  return getConsignors()
    .then((response) => {
      if (response.status === 200) {
        setConsignors(response.data);
        setLoading(false);
      }
    })
    .catch((error) => {
      console.log(error.response);
      setLoading(false);
    });
}

export function addConsignor(values, loadConsignors, closeModal, helpers) {
  return createConsignor(values)
    .then((response) => {
      if (response && response.status === 200) {
        helpers.resetForm();
        closeModal();
        toast_notification(
          'success',
          `${values.username} created successfully!`
        );
        loadConsignors();
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error && error.response.status === 400) {
        helpers.setFieldError('username', error.response.data.message);
        helpers.setFieldValue('password', '', false);
        helpers.setFieldTouched('password', false, false);
      }
    });
}

export function consignorUpdate(
  id,
  name,
  number,
  username,
  closeModal,
  helpers
) {
  return confirm_dialog(
    'Are you sure?',
    `You want to update details of <b>${username}</b> !`,
    'warning',
    'Yes, Update it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await updateConsignor({ id, name, number })
          .then((response) => {
            if (response && response.status === 200) {
              helpers.resetForm();
              closeModal();
              toast_notification(
                'success',
                `Consignor details have been updated!`
              );
            }
          })
          .catch((error) => {
            toast_notification('error', `${error.response.data.message}`);
          });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function consignorDelete(id, username, loadConsignors) {
  return confirm_dialog(
    'Are you sure?',
    `You want to delete <b>${username}</b> !`,
    'warning',
    'Yes, delete it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await deleteConsignor(id)
          .then((response) => {
            if (response.status === 200) {
              loadConsignors();
              toast_notification('success', `${username} delete successfully!`);
            } else {
              toast_notification('error', `Something goes wrong! 👎🏻`);
            }
          })
          .catch((error) => {
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
}

export const updateConsignorPassword = async (
  id,
  password,
  username,
  closeModal,
  helpers
) => {
  return await confirm_dialog(
    'Are you sure?',
    `You want to change password of <b>${username}</b>!`,
    'warning',
    'Yes, delete it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await consignorPasswordUpdate(id, password)
          .then((response) => {
            if (response.status === 200) {
              helpers.resetForm();
              closeModal();
              toast_notification(
                'success',
                `${username}'s password has been changed!`
              );
            } else {
              toast_notification('error', `Something goes wrong! 👎🏻`);
            }
          })
          .catch((error) => {
            console.log(error.response);
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
};
