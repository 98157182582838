import React from 'react';
import img_empty from '../../assets/images/undraw_empty_xct9.svg';

const index = () => {
  return (
    <div className='my-5 text-center'>
      <img
        src={img_empty}
        className='mx-auto d-block img-fluid'
        alt='No record found'
        width='350'
      />
      <div className='mt-5'>
        <h3 className='font-weight-bolder'>No record found!</h3>
      </div>
    </div>
  );
};

export default index;
