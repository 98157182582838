import React from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

const FormControl = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <React.Fragment>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        isInvalid={meta.touched && meta.error}
        {...field}
        {...props}
      />
      <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
    </React.Fragment>
  );
};

export default FormControl;
