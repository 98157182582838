import React, { Fragment, useEffect } from 'react';
import { Container, Row, Card, Col } from 'react-bootstrap';
import { getCurrentUser } from '../helpers/auth';
import { BsBriefcaseFill, BsPeopleFill } from 'react-icons/bs';
import { FaFileInvoice } from 'react-icons/fa';

const Dashboard = () => {
  const user = getCurrentUser();

  useEffect(() => {
    document.title = 'Ambica Roadlines - Dashboard';
  }, []);

  return (
    <Container>
      {user && (
        <Fragment>
          <Row className='my-4'>
            {user.role === '1' && (
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <Card className='mb-3'>
                  <Card.Body>
                    <Row className='align-items-center'>
                      <Col>
                        <BsBriefcaseFill size={55} />
                      </Col>
                      <Col>
                        <div className='text-right'>
                          <Card.Title className='text-muted'>
                            Employees
                          </Card.Title>
                          <Card.Text as='h1' className='font-weight-bold'>
                            00
                          </Card.Text>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col
              xl={user.role !== '1' ? 6 : 4}
              lg={user.role !== '1' ? 6 : 4}
              md={12}
              sm={12}
              xs={12}
            >
              <Card className='mb-3'>
                <Card.Body>
                  <Row className='align-items-center'>
                    <Col>
                      <BsPeopleFill size={55} />
                    </Col>
                    <Col>
                      <div className='text-right'>
                        <Card.Title className='text-muted'>
                          Consignors
                        </Card.Title>
                        <Card.Text as='h1' className='font-weight-bold'>
                          00
                        </Card.Text>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xl={user.role !== '1' ? 6 : 4}
              lg={user.role !== '1' ? 6 : 4}
              md={12}
              sm={12}
              xs={12}
            >
              <Card className='mb-3'>
                <Card.Body>
                  <Row className='align-items-center'>
                    <Col>
                      <FaFileInvoice size={55} />
                    </Col>
                    <Col>
                      <div className='text-right'>
                        <Card.Title className='text-muted'>Invoices</Card.Title>
                        <Card.Text as='h1' className='font-weight-bold'>
                          00
                        </Card.Text>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  );
};

export default Dashboard;
