import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import AddConsignorsModal from '../components/AddConsignorsModal';

import ConsignorsTable from '../components/ConsignorsTable';
import SkeletonTableLoading from '../components/loading/SkeletonTableLoading';

import { getConsignorsList } from '../functions/consignors';
import { searchData } from '../functions/searchFromTable';

const Consignors = () => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [consignors, setConsignors] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);

  const loadConsignors = async () => {
    await getConsignorsList(setConsignors, setLoading);
  };

  useEffect(() => {
    document.title = 'Ambica Roadlines - Consignors';

    setLoading(true);
    loadConsignors();
  }, []);

  return (
    <Container>
      <div className='mt-3'>
        {loading ? (
          <SkeletonTableLoading />
        ) : (
          <Fragment>
            <Row>
              <Col xl={{ span: 6, offset: 6 }}>
                <Row>
                  <Col
                    xl={8}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 12 }}
                  >
                    <Form.Control
                      type='search'
                      placeholder='Search...'
                      className='mt-xs-1'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Col>
                  <Col
                    xl={4}
                    md={{ span: 4 }}
                    sm={{ span: 4 }}
                    xs={{ span: 12 }}
                  >
                    <Button onClick={() => setModalOpen(true)} block>
                      Create Consignor
                    </Button>
                  </Col>
                  <AddConsignorsModal
                    modalOpen={modalOpen}
                    closeModal={closeModal}
                    loadConsignors={loadConsignors}
                  />
                </Row>
              </Col>
            </Row>
            <hr />
            <ConsignorsTable
              consignors={searchData(consignors, search)}
              loadConsignors={loadConsignors}
            />
          </Fragment>
        )}
      </div>
    </Container>
  );
};

export default Consignors;
