import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm, Spinner } from 'react-bootstrap';

import BootstrapModal from './BootstrapModal';
import FormControl from './FormControl';
import { consignorUpdate } from '../functions/consignors';

const UpdateConsignorsDetailsModal = ({
  modalOpen,
  closeModal,
  consignor,
  loadConsignors,
}) => {
  const initialValues = {
    name: consignor.name,
    number: consignor.number,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required()
      .min(5)
      .max(20)
      .matches(/^[a-zA-Z ]*$/, 'Name should contain only alphabetical value.')
      .label('Name'),
    number: Yup.string()
      .matches(/^[0-9]*$/, 'Phone Number should contain only numerical value.')
      .required()
      .min(10)
      .max(10)
      .label('Phone Number'),
  });

  const onSubmit = async ({ name, number }, helpers) => {
    await consignorUpdate(
      consignor.id,
      name,
      number,
      consignor.username,
      closeModal,
      helpers
    ).then(() => {
      loadConsignors();
    });
  };

  return (
    <BootstrapModal
      show={modalOpen}
      onHide={closeModal}
      title={`Update details of ${consignor.username}`}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <BootstrapForm.Group controlId='name'>
              <FormControl
                type='text'
                label='Consignor Name'
                name='name'
                tabIndex={1}
              />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='number'>
              <FormControl
                type='text'
                label='Phone Number'
                name='number'
                tabIndex={2}
              />
            </BootstrapForm.Group>
            <Button
              type='submit'
              tabIndex={3}
              block
              disabled={!(dirty && isValid) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation='border' size='sm' />
              ) : (
                'Update'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </BootstrapModal>
  );
};

export default UpdateConsignorsDetailsModal;
