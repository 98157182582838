import {
  fetchConsignees,
  createConsignee,
  consigneeDelete,
} from '../helpers/consignees';

import { confirm_dialog, toast_notification } from './notification';

export const allConsignees = async (
  id,
  setConsignees,
  setConsignorsName,
  setConsignorsUsername,
  setError
) => {
  return await fetchConsignees(id)
    .then((response) => {
      setConsignees(response.data.consignees);
      setConsignorsName(response.data.name);
      setConsignorsUsername(response.data.username);
      setError('');
    })
    .catch((error) => {
      if (error && error.response !== 200) {
        setError(error.response.data.message);
      }
      console.log(error.response);
    });
};

export async function addConsignee(name, id, username, helpers) {
  await createConsignee(name, id)
    .then((response) => {
      if (response && response.status === 200) {
        helpers.resetForm();
        toast_notification('success', `${name} Added under ${username}`);
      }
    })
    .catch((error) => {
      if (error && error.response.status === 400) {
        helpers.setFieldError('name', error.response.data.message);
      }
      if (error && error.response.status === 401) {
        helpers.setFieldError('name', error.response.data.message);
        helpers.setFieldValue('name', '', false);
      }
    });
}

export async function deleteConsignee(id, name) {
  return confirm_dialog(
    'Are you sure?',
    `You want to delete <b>${name}</b> !`,
    'warning',
    'Yes, delete it!'
  )
    .then(async (result) => {
      if (result.isConfirmed) {
        await consigneeDelete(id)
          .then((response) => {
            if (response.status === 200) {
              toast_notification('success', `${name} delete successfully!`);
            } else {
              toast_notification('error', `Something goes wrong! 👎🏻`);
            }
          })
          .catch((error) => {
            toast_notification('error', `${error.response.data.message}!`);
          });
      }
    })
    .catch();
}
