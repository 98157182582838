import axios from 'axios';
import { getJwt } from './auth';

const BASE_URL = process.env.REACT_APP_API;

export const getConsignors = async () => {
  const result = axios.get(`${BASE_URL}/users/getConsignors`, {
    headers: {
      authorization: `Bearer ${getJwt()}`,
    },
  });
  return result;
};

export const createConsignor = async (values) => {
  const result = axios.post(
    `${BASE_URL}/users/addconsignor`,
    JSON.stringify(values),
    {
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return result;
};

export const updateConsignor = async ({ id, name, number }) => {
  const result = axios.put(
    `${BASE_URL}/users/consignor`,
    JSON.stringify({ id, name, number }),
    {
      headers: {
        authorization: `Bearer ${getJwt()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return result;
};

export const deleteConsignor = async (id) => {
  const result = axios.delete(`${BASE_URL}/users/consignor/${id}`, {
    headers: {
      authorization: `Bearer ${getJwt()}`,
    },
  });
  return result;
};

export const consignorPasswordUpdate = async (id, password) => {
  const result = await axios({
    method: 'PUT',
    url: `${BASE_URL}/users/passupconsignor`,
    data: JSON.stringify({ id, password }),
    headers: {
      authorization: `Bearer ${getJwt()}`,
      'Content-Type': 'application/json',
    },
  });
  return result;
};
