import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { HiPrinter } from 'react-icons/hi';
import { GetBilty } from '../../functions/bills';

import Dreamer from '../../assets/images/undraw_dreamer_gxxi.svg';

const GetBill = () => {
  const { billId } = useParams();

  const [data, setData] = useState({});
  const [dataItems, setDataItems] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchData = async (billId) => {
    setLoading(true);
    setError('');
    await GetBilty(billId, setData, setDataItems, setError, setLoading);
  };

  useEffect(() => {
    document.title = `Ambica Roadlines - Billty No ${billId}`;
    fetchData(billId);
  }, [billId]);

  return (
    <Container>
      <div>
        {loading && <div>Loading...</div>}
        {!loading && (
          <Fragment>
            {error && (
              <div className='my-5 py-5'>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    src={Dreamer}
                    className='img-fluid'
                    alt='Dreamer'
                    width='350'
                  />
                </div>
                <div className='text-center mt-5'>
                  <h5 className='text-lowercase font-weight-bold'>{error}</h5>
                </div>
              </div>
            )}
            {!error && (
              <Fragment>
                <div className='mt-3'>
                  <div className='d-print-none d-flex justify-content-between align-items-center'>
                    <div className='text-center text-center'>
                      <div className='small text-muted'>G.C. Number</div>
                      <h1 className='m-0'>{data.gcNumber}</h1>
                    </div>
                    <Button onClick={() => window.print()}>
                      <HiPrinter /> Print Billty
                    </Button>
                  </div>
                  <div className='d-none d-print-block'>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-2'>
                        <div className='text-md-left text-center'>
                          <div className='small'>G.C. Number</div>
                          <h1 className='m-0'>{data.gcNumber}</h1>
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='text-center'>
                          <div>All disputes to dwarka jusrisdiction</div>
                          <h2 className='m-0 font-weight-bold'>
                            AMBICA ROADLINES
                          </h2>
                          <div className='text-muted'>
                            TRANSPORT CONTRACTORS
                          </div>
                          <div>
                            Godown area, Mithapur - 361345, Dist. Devbhumi
                            Dwarka (Guj.)
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        <ul className='list-inline text-center text-md-right'>
                          <li>(02892) 226529</li>
                          <li>+91 99988 18311</li>
                          <li>+91 99988 18312</li>
                          <li>{data.date}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='row mt-3'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                      <div className='p-3 border rounded'>
                        <div>
                          Truck Number: <strong>{data.truckNumber}</strong>
                        </div>
                        <div>
                          Drive Phone Number:{' '}
                          <strong>{data.driverNumber}</strong>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                      <div className='m-md-0 mt-sm-3 mt-3 p-3 border rounded'>
                        <div>
                          From: <strong>{data.fromCity}</strong>
                        </div>
                        <div>
                          To: <strong>{data.toCity}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                      <div className='p-3 border rounded'>
                        <div>
                          Consignor: <strong>{data.consignorName}</strong>
                        </div>
                        <div>
                          Remarks: <strong>{data.consignorRemark}</strong>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                      <div className='m-md-0 mt-sm-3 mt-3 p-3 border rounded'>
                        <div>
                          Consignee: <strong>{data.consigneeName}</strong>
                        </div>
                        <div>
                          Remarks: <strong>{data.consigneeRemark}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='table-responsive'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Description of goods</th>
                          <th>Quantity</th>
                          <th>Weight</th>
                          <th>Challan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataItems.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.descriptionOfGoods}</td>
                            <td>{item.quantity}</td>
                            <td>{item.weight}</td>
                            <td>{item.challan}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan='2'>
                            To Pay: <strong>{data.toPay}</strong>
                          </td>
                          <td colSpan='3'>
                            Freight to pay (in words):{' '}
                            <strong>{data.freight}</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                      <div className=''>
                        Invoice Number: <strong>{data.invoiceNumber}</strong>
                      </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                      <div className=''>
                        Send To: <strong>{data.sendTo}</strong>
                      </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                      <div className=''>
                        GST To: <strong>{data.gstTo}</strong>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <Row className='align-items-center my-5'>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <ul
                        className='pl-0 m-0 ml-3 small text-muted'
                        style={{ listStyle: 'decimal' }}
                      >
                        <li>Goods booked subject to condition over leat</li>
                        <li>
                          Goods consigned in the name of party will be delivered
                          direted to consignee name without obtaining consignee
                          copy.
                        </li>
                      </ul>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <div className='text-center m-0 my-4'>
                        <h6>R.M. Bhanushali</h6>
                        <h6 className='m-0'>PAN No. ACWPB7754N</h6>
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <div className='mb-3 border p-5 rounded'></div>
                      <h6 className='text-center'>For, Ambica Roadlines</h6>
                    </Col>
                  </Row>
                  <hr />
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Container>
  );
};

export default GetBill;
