import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const toast_notification = (icon, title) => {
  return Toast.fire({
    icon: icon,
    title: title,
  });
};

export const confirm_dialog = (title, text, icon, confirmButtonText) => {
  return Swal.fire({
    title: `${title}`,
    html: `${text}`,
    icon: icon,
    showCancelButton: true,
    cancelButtonColor: '#dc3545',
    confirmButtonColor: '#28a745',
    confirmButtonText: `${confirmButtonText ?? 'Yes!'}`,
    width: '450px',
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
};
