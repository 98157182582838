import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Form, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BiltyListTable from '../../components/BiltyListTable';
import SkeletonTableLoading from '../../components/loading/SkeletonTableLoading';
import { displayListOfBilty } from '../../functions/bills';
import NoRecord from '../../components/NoRecord';
import { Fragment } from 'react';
import { searchData } from '../../functions/searchFromTable';

const Bilty = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState('');
  const [search, setSearch] = useState('');
  const [totalResults, setTotalResults] = useState();

  useEffect(() => {
    document.title = `Ambica Roadlines - Billty`;
    setLoading(true);
    displayListOfBilty(page, setItems, setErrors, setTotalResults, setLoading);
    return () => {
      setItems([]);
      setErrors('');
      setSearch('');
      setTotalResults(null);
    };
  }, [page]);

  const tablePagination = () => {
    const paginate = (pageNum) => setPage(pageNum);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalResults / 10); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {pageNumbers.map((num) => (
          <Pagination.Item
            key={num}
            active={num === page}
            onClick={() => paginate(num)}
          >
            {num}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  return (
    <Container>
      <div className='mt-3'>
        {loading && <SkeletonTableLoading />}
        {!loading && (
          <Fragment>
            <Row>
              <Col xl={{ span: 6, offset: 6 }}>
                <Row>
                  <Col
                    xl={8}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 12 }}
                  >
                    {items.length > 0 && (
                      <Form.Control
                        type='search'
                        placeholder='Search...'
                        className='mt-xs-1'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    )}
                  </Col>
                  <Col
                    xl={4}
                    md={{ span: 4 }}
                    sm={{ span: 4 }}
                    xs={{ span: 12 }}
                  >
                    <Button as={Link} to='/bills/bilty/create' block>
                      Create Bilty
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                {errors && <NoRecord />}
                {!errors && (
                  <Fragment>
                    {tablePagination()}
                    <BiltyListTable items={searchData(items, search)} />
                  </Fragment>
                )}
              </Col>
            </Row>
          </Fragment>
        )}
      </div>
    </Container>
  );
};

export default Bilty;
