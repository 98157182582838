import React from 'react';
import { Modal } from 'react-bootstrap';

const BootstrapModal = ({ show, onHide, title, children }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton className='align-items-center'>
        <Modal.Title>
          <span className='font-weight-bold text-capitalize small'>{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default BootstrapModal;
