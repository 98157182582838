import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Form as BSForm } from 'react-bootstrap';

import FormControl from '../../components/FormControl';
import { fetchConsignees } from '../../helpers/consignees';
import { generateBitly, initialBilling } from '../../functions/bills';
import { toast_notification } from '../../functions/notification';

import CityList from '../../data/city.json';
import { Typeahead } from 'react-bootstrap-typeahead';

const CreateBilty = () => {
  const history = useHistory();

  const [consignors, setConsignors] = useState([]);
  const [financialYear, setFinancialYear] = useState('');
  const [consignees, setConsignees] = useState([]);
  const [fromCity, setFromCity] = useState([]);
  const [toCity, setToCity] = useState([]);
  const [loading, setLoading] = useState(false);

  const preLoaded = async () => {
    setLoading(true);
    await initialBilling(setConsignors, setLoading, setFinancialYear);
  };

  const clearPreviousState = () => {
    setConsignors([]);
    setFinancialYear('');
    setConsignees([]);
    setFromCity([]);
    setToCity([]);
  };

  useEffect(() => {
    document.title = `Ambica Roadlines - Create Billty`;
    preLoaded();
    clearPreviousState();
    return () => {
      clearPreviousState();
    };
  }, []);

  const initialValues = {
    truckNumber: '',
    driverNumber: '',
    fromCity: '',
    toCity: '',
    consignorId: '',
    consigneeId: '',
    consignorRemark: '',
    consigneeRemark: '',
    toPay: '',
    invoiceNumber: '',
    sendTo: 1,
    gstTo: 1,
    items: [
      {
        descriptionOfGoods: '',
        weight: '',
        challan: '',
        quantity: '',
      },
    ],
  };

  const validationSchema = Yup.object({
    truckNumber: Yup.string().required().uppercase().label('Truck Number'),
    driverNumber: Yup.string()
      .matches(/^[0-9]*$/, 'Phone Number should contain only numerical value.')
      .required()
      .min(10)
      .max(10)
      .label('Driver Phone Number'),
    fromCity: Yup.number().required().label('From city'),
    toCity: Yup.number()
      .required()
      .notOneOf(
        [Yup.ref('fromCity')],
        `'From city' & 'To city' must be different!`
      )
      .label('To city'),
    consignorId: Yup.number().required().label('Consignor'),
    consigneeId: Yup.number().required().label('Consignee'),
    consignorRemark: Yup.string().notRequired().label('Consignor remark'),
    consigneeRemark: Yup.string().notRequired().label('Consignee remark'),
    toPay: Yup.number().integer().positive().notRequired().label('To pay'),
    invoiceNumber: Yup.string().notRequired().label('Invoice Number'),
    sendTo: Yup.number().notRequired().default(1).label('Send to'),
    gstTo: Yup.number().required().default(1).label('GST to'),
    items: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          descriptionOfGoods: Yup.string()
            .required()
            .label('Description of goods'),
          weight: Yup.number().required().label('Weight'),
          challan: Yup.string().required().label('Challan'),
          quantity: Yup.number().min(1).required().label('Quantity'),
        })
      ),
  });

  const onSubmit = async (values, helpers) => {
    await generateBitly(values)
      .then((response) => {
        if (response && response.status === 200) {
          toast_notification(
            'success',
            'Your information has been submitted successfully.'
          );
          history.push(`/bills/bilty/${response.data.billid}`);
        }
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          console.log(error.response);
          helpers.setFieldError('errorMessage', error.response.data.message);
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleOnChange = async (id) => {
    await fetchConsignees(id)
      .then((response) => {
        setConsignees(response.data.consignees);
      })
      .catch((error) => {
        console.log(error.response);
        setConsignees([]);
      });
  };

  const handleFromCityChange = async (e, setFieldValue, setFromCity) => {
    const value = e.length > 0 ? e[0].id : '';
    setFieldValue('fromCity', value);
    setFromCity(e);
  };

  const handleToCityChange = (e, setFieldValue, setToCity) => {
    const value = e.length > 0 ? e[0].id : '';
    setFieldValue('toCity', value);
    setToCity(e);
  };

  return (
    <Container>
      {loading && (
        <div className='min-vh-100 d-flex justify-content-center align-items-center'>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      {!loading && (
        <Fragment>
          <div className='my-3'>
            <h5>Create Bilty</h5>
            <hr />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              isSubmitting,
              isValid,
              dirty,
              setFieldValue,
              setFieldTouched,
              setFieldError,
              touched,
            }) => (
              <Form autoComplete='off'>
                {errors.errorMessage && (
                  <Alert variant='danger' dismissible>
                    {errors.errorMessage}
                  </Alert>
                )}
                <Row>
                  <Col xl={4} ld={4} md={6} xs={12}>
                    <BSForm.Group controlId='financialYear'>
                      <FormControl
                        type='text'
                        label='Financial year'
                        name='financialYear'
                        value={financialYear}
                        disabled
                      />
                    </BSForm.Group>
                  </Col>
                  <Col xl={4} ld={4} md={6} xs={12}>
                    <BSForm.Group controlId='truckNumber'>
                      <FormControl
                        type='text'
                        label='Truck number'
                        name='truckNumber'
                        onInput={toInputUppercase}
                      />
                    </BSForm.Group>
                  </Col>
                  <Col xl={4} ld={4} md={12} xs={12}>
                    <BSForm.Group controlId='driverNumber'>
                      <FormControl
                        type='text'
                        label='Driver phone number'
                        name='driverNumber'
                      />
                    </BSForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId='fromCity'>
                      <BSForm.Label>From city</BSForm.Label>
                      <Typeahead
                        id='fromCity'
                        labelKey='name'
                        onChange={(e) => {
                          handleFromCityChange(e, setFieldValue, setFromCity);
                        }}
                        onBlur={(e) => {
                          setFieldTouched('fromCity', true);
                          setFieldError('fromCity', true);
                        }}
                        options={CityList}
                        placeholder='Choose a city...'
                        selected={fromCity}
                        renderMenuItemChildren={(option) => (
                          <div>
                            {option.name}
                            <div className='text-muted small'>
                              State: {option.statename}
                            </div>
                          </div>
                        )}
                      />
                      <div className='text-danger small mt-1'>
                        {touched.fromCity && errors.fromCity}
                      </div>
                    </BSForm.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId='toCity'>
                      <BSForm.Label>To city</BSForm.Label>
                      <Typeahead
                        id='toCity'
                        labelKey='name'
                        onChange={(e) => {
                          handleToCityChange(e, setFieldValue, setToCity);
                        }}
                        onBlur={(e) => {
                          setFieldTouched('toCity', true);
                          setFieldError('toCity', true);
                        }}
                        options={CityList}
                        placeholder='Choose a city...'
                        selected={toCity}
                        renderMenuItemChildren={(option) => (
                          <div>
                            {option.name}
                            <div className='text-muted small'>
                              State: {option.statename}
                            </div>
                          </div>
                        )}
                      />
                      <div className='text-danger small mt-1'>
                        {touched.toCity && errors.toCity}
                      </div>
                    </BSForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId='consignorId'>
                      <FormControl
                        as='select'
                        label='Consignor'
                        name='consignorId'
                        value={undefined}
                        className='custom-select'
                        onChange={(e) => {
                          if (e.target.value !== '') {
                            handleOnChange(e.target.value);
                            setFieldValue(
                              'consignorId',
                              parseInt(e.target.value)
                            );
                          } else {
                            setConsignees([]);
                            setFieldValue('consignorId', null);
                          }
                        }}
                      >
                        <option label='Select a consignor' defaultValue />
                        {consignors.map((c, i) => (
                          <option key={i} value={c.id} label={c.name} />
                        ))}
                      </FormControl>
                    </BSForm.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId='consigneeId'>
                      <FormControl
                        as='select'
                        label='Consignee'
                        name='consigneeId'
                        value={undefined}
                        onChange={(e) => {
                          setFieldValue(
                            'consigneeId',
                            parseInt(e.target.value)
                          );
                        }}
                        className='custom-select'
                        disabled={consignees.length <= 0}
                      >
                        {consignees.length <= 0 && (
                          <option label='No Consignee available' />
                        )}
                        {consignees.length > 0 && (
                          <option label='Select a consignee' defaultValue />
                        )}
                        {consignees.map((c, i) => (
                          <option key={i} value={c.id} label={c.name} />
                        ))}
                      </FormControl>
                    </BSForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId={`consignorRemark`}>
                      <FormControl
                        as='textarea'
                        rows={2}
                        label='Consignor remark'
                        name={`consignorRemark`}
                        style={{ resize: 'none' }}
                      />
                    </BSForm.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId={`consigneeRemark`}>
                      <FormControl
                        as='textarea'
                        rows={2}
                        label='Consignee remark'
                        name={`consigneeRemark`}
                        style={{ resize: 'none' }}
                      />
                    </BSForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId={`toPay`}>
                      <FormControl type='text' label='To pay' name={`toPay`} />
                    </BSForm.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <BSForm.Group controlId={`invoiceNumber`}>
                      <FormControl
                        type='text'
                        label='Invoice number'
                        name={`invoiceNumber`}
                      />
                    </BSForm.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <BSForm.Group controlId='sendTo'>
                      <FormControl
                        as='select'
                        label='Send to'
                        name='sendTo'
                        value={undefined}
                        onChange={(e) => {
                          setFieldValue('sendTo', parseInt(e.target.value));
                        }}
                        className='custom-select'
                      >
                        <option value={1} label='Consignor' />
                        <option value={2} label='Consignee' />
                        <option value={3} label='No Bill' />
                      </FormControl>
                    </BSForm.Group>
                  </Col>
                  <Col>
                    <BSForm.Group controlId='gstTo'>
                      <FormControl
                        as='select'
                        label='GST to'
                        name='gstTo'
                        value={undefined}
                        onChange={(e) => {
                          setFieldValue('gstTo', parseInt(e.target.value));
                        }}
                        className='custom-select'
                      >
                        <option value={1} label='Consignor' />
                        <option value={2} label='Consignee' />
                        <option value={4} label='Transport' />
                      </FormControl>
                    </BSForm.Group>
                  </Col>
                </Row>

                <div className='my-3'>
                  <h5>Add items</h5>
                  <hr />
                </div>
                <FieldArray name='items'>
                  {({ insert, push, remove }) => (
                    <Fragment>
                      <BSForm.Group>
                        <Button
                          type='button'
                          variant='info'
                          onClick={() =>
                            push({
                              descriptionOfGoods: '',
                              weight: '',
                              challan: '',
                              quantity: '',
                            })
                          }
                        >
                          Add anothe item
                        </Button>
                      </BSForm.Group>
                      {values.items.length > 0 &&
                        values.items.map((item, index) => (
                          <Fragment key={index}>
                            <Row>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BSForm.Group
                                  controlId={`items.${index}.descriptionOfGoods`}
                                >
                                  <FormControl
                                    type='text'
                                    label='Description of goods'
                                    name={`items.${index}.descriptionOfGoods`}
                                  />
                                </BSForm.Group>
                              </Col>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BSForm.Group
                                  controlId={`items.${index}.weight`}
                                >
                                  <FormControl
                                    type='text'
                                    label='Weight'
                                    name={`items.${index}.weight`}
                                  />
                                </BSForm.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BSForm.Group
                                  controlId={`items.${index}.challan`}
                                >
                                  <FormControl
                                    type='text'
                                    label='Challan'
                                    name={`items.${index}.challan`}
                                  />
                                </BSForm.Group>
                              </Col>
                              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BSForm.Group
                                  controlId={`items.${index}.quantity`}
                                >
                                  <FormControl
                                    type='number'
                                    min='1'
                                    label='Quantity'
                                    name={`items.${index}.quantity`}
                                  />
                                </BSForm.Group>
                              </Col>
                            </Row>
                            <BSForm.Group>
                              <Button
                                type='button'
                                variant='danger'
                                onClick={(index) => remove(index)}
                              >
                                Delete item {index + 1}
                              </Button>
                            </BSForm.Group>
                          </Fragment>
                        ))}
                    </Fragment>
                  )}
                </FieldArray>
                <BSForm.Group>
                  <Button
                    type='submit'
                    disabled={!(dirty && isValid) || isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation='border' size='sm' />
                    ) : (
                      'Create Bilty'
                    )}
                  </Button>
                </BSForm.Group>
              </Form>
            )}
          </Formik>
        </Fragment>
      )}
    </Container>
  );
};

export default CreateBilty;
