import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm, Spinner } from 'react-bootstrap';

import BootstrapModal from './BootstrapModal';
import FormControl from './FormControl';

import { addEmployee } from '../functions/employees';

const AddEmployeeModal = ({ modalOpen, closeModal, loadEmployees }) => {
  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required()
      .min(8)
      .max(30)
      .matches(
        /^[a-zA-Z0-9]*$/,
        'Username should contain only alphanumeric value.'
      )
      .label('Username'),
    password: Yup.string().required().min(3).max(20).label('Password'),
  });

  const onSubmit = async ({ username, password }, helpers) => {
    await addEmployee(username, password, closeModal, loadEmployees, helpers);
  };

  return (
    <BootstrapModal
      show={modalOpen}
      onHide={closeModal}
      title='Add Employee'
      ButtonValue='Add'
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <BootstrapForm.Group controlId='username'>
              <FormControl
                type='text'
                label='Username'
                name='username'
                tabIndex={1}
              />
            </BootstrapForm.Group>
            <BootstrapForm.Group controlId='password'>
              <FormControl
                type='password'
                label='Password'
                name='password'
                tabIndex={2}
                autoComplete='false'
              />
            </BootstrapForm.Group>
            <Button
              type='submit'
              tabIndex={3}
              block
              disabled={!(dirty && isValid) || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation='border' size='sm' />
              ) : (
                'Create Employee'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </BootstrapModal>
  );
};

export default AddEmployeeModal;
