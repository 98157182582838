import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { consignorDelete } from '../functions/consignors';
import { getCurrentUser } from '../helpers/auth';
import UpdateConsignorsDetailsModal from './UpdateConsignorsDetailsModal';
import UpdateConsignorsPasswordModal from './UpdateConsignorsPasswordModal';

const ConsignorsTable = ({ consignors, loadConsignors }) => {
  const [updateDetailsModalOpen, setUpdateDetailsModalOpen] = useState(false);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);
  const [currentConsignor, setCurrentConsignor] = useState({});

  const user = getCurrentUser();
  let history = useHistory();

  const closeUpdateDetailsModal = () => {
    setUpdateDetailsModalOpen(false);
    setCurrentConsignor({});
  };

  const closeUpdatePasswordModal = () => {
    setUpdatePasswordModalOpen(false);
    setCurrentConsignor({});
  };

  return (
    <div className='table-responsive'>
      <Table striped bordered hover>
        <caption>List of consignors</caption>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Username</th>
            <th scope='col'>Consignor Name</th>
            <th scope='col'>Mobile Number</th>
            <th scope='col'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {consignors.map((consignor, index) => (
            <tr key={index}>
              <th>{consignor.id}</th>
              <td>{consignor.username}</td>
              <td>{consignor.name}</td>
              <td>{consignor.number}</td>
              <td>
                <div className='d-flex flex-lg-row flex-sm-column flex-column'>
                  <Button
                    size='sm'
                    variant='success'
                    className='mb-lg-0 mr-lg-2 mb-1'
                    onClick={() => history.push(`/consignee/${consignor.id}`)}
                  >
                    Consignees
                  </Button>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='mb-lg-0 mr-lg-2 mb-1'
                    onClick={() => {
                      setUpdateDetailsModalOpen(true);
                      setCurrentConsignor(consignor);
                    }}
                  >
                    Update Details
                  </Button>

                  {user.role === '1' && (
                    <Button
                      size='sm'
                      variant='info'
                      className='mb-lg-0 mr-lg-2 mb-1'
                      onClick={() => {
                        setUpdatePasswordModalOpen(true);
                        setCurrentConsignor(consignor);
                      }}
                    >
                      Update Password
                    </Button>
                  )}

                  <Button
                    size='sm'
                    variant='danger'
                    className='mb-lg-0 mr-lg-2 mb-1'
                    onClick={() => {
                      consignorDelete(
                        consignor.id,
                        consignor.username,
                        loadConsignors
                      );
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UpdateConsignorsDetailsModal
        modalOpen={updateDetailsModalOpen}
        closeModal={closeUpdateDetailsModal}
        consignor={currentConsignor}
        loadConsignors={loadConsignors}
      />
      <UpdateConsignorsPasswordModal
        modalOpen={updatePasswordModalOpen}
        closeModal={closeUpdatePasswordModal}
        consignor={currentConsignor}
      />
    </div>
  );
};

export default ConsignorsTable;
